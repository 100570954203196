import { FormInstance } from "antd";
import { Container, Divider, Form2, Icon, Typography } from "components";
import React from "react";
import { WizardPageProps } from ".";
import { useIntl } from "utils/context";

interface IFormData {
  businessCountry: string;
  businessNumber: string;
}

export default function EnterBusinessNumber({ taxProfileWizard, onContinue, isLoading }: WizardPageProps) {
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    businessCountry: taxProfileWizard.businessCountry || taxProfileWizard.address?.country || "",
    businessNumber: taxProfileWizard.businessNumber ?? "",
  };

  function onSubmit({ businessCountry, businessNumber }: IFormData) {
    onContinue({
      businessCountry,
      businessNumber,
    });
  }

  return (
    <Form2
      initialValues={initialValues}
      validateTrigger="onChange"
      requiredMark={false}
      onFinish={onSubmit}
      key={`initialBusinessCountry-${initialValues.businessCountry}`}
    >
      <Container padding="none">
        <fieldset>
          <legend>
            <Typography.Title>
              {formatMessage({ id: "containers.taxProfile.wizard.enterBusinessNumberTitle" })}
            </Typography.Title>
          </legend>
          <Divider transparent size="large" />
          <Form2.Item name="businessCountry">
            <Form2.SelectCountry />
          </Form2.Item>
          <Form2.Item name="businessNumber">
            <Form2.Input
              type="text"
              placeholder={formatMessage({ id: "containers.taxProfile.wizard.businessNumber" })}
              maxLength={20}
            />
          </Form2.Item>
        </fieldset>
        <Divider transparent size="large" />
        <Form2.Item dependencies={["businessCountry", "businessNumber"]}>
          {(form: FormInstance<IFormData>) => {
            const businessCountry = form.getFieldValue("businessCountry");
            const businessNumber = form.getFieldValue("businessNumber");

            return (
              <Form2.SubmitButton
                disabled={!businessCountry || !businessNumber || isLoading}
                text={formatMessage({ id: "common.continue" })}
                suffix={<Icon type="arrow-right" />}
              />
            );
          }}
        </Form2.Item>
      </Container>
    </Form2>
  );
}
