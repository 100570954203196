import { Button, Container, Divider, Icon, Typography } from "components";
import React from "react";
import { useIframeConfig } from "store/hooks/config";
import { useIntl } from "utils/context";
import { WizardPageProps } from ".";

export default function GreetingMessage({ onContinue, isLoading }: WizardPageProps) {
  const config = useIframeConfig();
  const { formatMessage } = useIntl();

  return (
    <Container padding="none">
      <Typography.Title style={{ marginBottom: "18px" }}>
        {formatMessage({ id: "containers.taxProfile.wizard.greetingMessageTitle" })}
      </Typography.Title>
      <Divider size="large" transparent />
      <Typography.Paragraph>
        {formatMessage({ id: "containers.taxProfile.wizard.greetingMessageParagraph1" })}
      </Typography.Paragraph>
      <Divider transparent />
      <Typography.Paragraph>
        {formatMessage({ id: "containers.taxProfile.wizard.greetingMessageParagraph2" })}
      </Typography.Paragraph>
      <Divider size="large" transparent />
      <Button
        type="primary"
        block
        onClick={() => {
          onContinue();
        }}
        disabled={isLoading}
      >
        {formatMessage({ id: "common.continue" })} <Icon type="arrow-right" />
      </Button>
      <Divider size="large" transparent />
      {config.privacyLink && (
        <Typography.Paragraph type="secondary">
          <Button
            type="link"
            target="_blank"
            rel="noopener noreferrer"
            href={
              /^(https|http)?:\/\//.test(config.privacyLink ?? "")
                ? config.privacyLink
                : `https://${config.privacyLink}`
            }
          >
            {formatMessage({
              id: "containers.taxProfile.wizard.greetingMessagePrivacyLink",
            })}
          </Button>
        </Typography.Paragraph>
      )}
    </Container>
  );
}
