import { FormInstance } from "antd";
import { Container, Divider, Form2, GreyBox, Icon, Typography } from "components";
import React from "react";
import { WizardPageProps } from ".";
import { useIntl } from "utils/context";

interface IFormData {
  confirmAddress: string;
}

export default function ConfirmPrimaryAddress({ taxProfileWizard, onContinue, isLoading }: WizardPageProps) {
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    confirmAddress: taxProfileWizard.confirmAddress ?? "",
  };

  function onSubmit({ confirmAddress }: IFormData) {
    onContinue({
      confirmAddress,
      ...(confirmAddress === "yes" && {
        address: { ...taxProfileWizard.payoutRecipientAddress },
      }),
    });
  }

  return (
    <Form2 initialValues={initialValues} validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
      <Container padding="none">
        <fieldset>
          <legend>
            <Typography.Title>
              {formatMessage({ id: "containers.taxProfile.wizard.confirmPrimaryAddressTitle" })}
            </Typography.Title>
          </legend>
          <Divider transparent />
          <GreyBox size="2x" border={false}>
            <Typography.Text strong>{taxProfileWizard.stringifiedPayoutAddress}</Typography.Text>
          </GreyBox>
          <Divider transparent size="xlarge" />
          <Form2.Item name="confirmAddress">
            <Form2.Radio.Group
              optionType="card"
              direction="vertical"
              name="radio"
              options={[
                {
                  label: formatMessage({ id: "containers.taxProfile.wizard.yesPrimaryAddress" }),
                  value: "yes",
                },
                {
                  label: formatMessage({ id: "containers.taxProfile.wizard.noPrimaryAddress" }),
                  value: "no",
                },
              ]}
            />
          </Form2.Item>
        </fieldset>
        <Divider transparent size="large" />
        <Form2.Item dependencies={["confirmAddress"]}>
          {(form: FormInstance<IFormData>) => {
            const confirmAddress = form.getFieldValue("confirmAddress");

            return (
              <Form2.SubmitButton
                disabled={!confirmAddress || isLoading}
                text={formatMessage({ id: "common.continue" })}
                suffix={<Icon type="arrow-right" />}
              />
            );
          }}
        </Form2.Item>
      </Container>
    </Form2>
  );
}
