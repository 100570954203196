import { TaxStatus } from "@trolley/common-frontend";
import {
  Container,
  Divider,
  Grid,
  Icon,
  LinkButton,
  List,
  Loader,
  Space,
  Status,
  Text,
  TitleBar,
  Tooltip,
} from "components";
import dayjs from "dayjs";
import { PATHS } from "pages/App/routes";
import React, { useEffect, useState } from "react";
import { TaxProfile } from "store/actions/taxProfiles";
import { useTaxProfiles } from "store/hooks/taxProfiles";
import { BaseStatus } from "store/reducers/standardReducer";
import { PRODUCT_MODULES, useIntl } from "utils/context";
import { emitEvent, WidgetEvent } from "utils/helpers";
import { useProfileComplete } from "utils/hooks";

export default function TaxProfiles() {
  const { formatMessage } = useIntl();
  const { data: taxProfiles, status } = useTaxProfiles();
  const isProfileComplete = useProfileComplete();
  const [pathname, setPathname] = useState<PATHS.INFO | PATHS.WIZARD_TAXPROFILE_NEW>(PATHS.INFO);

  useEffect(() => {
    emitEvent({
      event: WidgetEvent.MODULE_LOADED,
      module: [PRODUCT_MODULES.PAY],
    });
  }, []);

  useEffect(() => {
    setPathname(isProfileComplete ? PATHS.WIZARD_TAXPROFILE_NEW : PATHS.INFO);
  }, [isProfileComplete]);

  function renderTaxProfile(item: Partial<TaxProfile>) {
    return (
      <Grid
        justify="space-between"
        alignItems="middle"
        wrap={false}
        padding="none"
        style={{ padding: "14px 0 14px 8px" }}
      >
        <Grid.Item>
          <Space size="small">
            <Icon size="xlarge" theme="light" type="file-alt" />
            <Space direction="column" align="start" size="none">
              <Text strong>
                {formatMessage({
                  id: "containers.taxProfile.euTaxProfile",
                })}
                {item.status && (
                  <Status
                    type={
                      {
                        [TaxStatus.SUBMITTED]: "info" as "info",
                        [TaxStatus.INCOMPLETE]: "warning" as "warning",
                        [TaxStatus.REVIEWED]: "success" as "success",
                        [TaxStatus.VOIDED]: "default" as "default",
                        [TaxStatus.DELETED]: "default" as "default",
                        [TaxStatus.EXPIRED]: "default" as "default",
                      }[item.status] || "default"
                    }
                    suffix={
                      item.status === TaxStatus.VOIDED &&
                      item.voidReason && (
                        <Tooltip
                          title={formatMessage(
                            {
                              id: "containers.taxProfile.voidedDueTo",
                            },
                            {
                              voidReason: (
                                <Text>
                                  <ul>
                                    <li>
                                      {formatMessage({
                                        id: `containers.accountSummary.taxVoidReason.${item.voidReason}`,
                                        defaultMessage: item.voidReason,
                                      })}
                                    </li>
                                  </ul>
                                </Text>
                              ),
                            },
                          )}
                        >
                          <Icon right type="question-circle" />
                        </Tooltip>
                      )
                    }
                  >
                    {item.status}
                  </Status>
                )}
              </Text>
              <Text type="secondary" size="small">
                {item.signedAt
                  ? formatMessage(
                      {
                        id: "common.submittedOn",
                      },
                      { date: dayjs(item.signedAt).format("ll") },
                    )
                  : formatMessage({ id: "containers.accountSummary.notSubmitted" })}
              </Text>
            </Space>
          </Space>
        </Grid.Item>
        <Grid.Item align="right">
          {item.status === TaxStatus.INCOMPLETE && (
            <Tooltip title={<Text size="small">{formatMessage({ id: "common.edit" })}</Text>}>
              <LinkButton
                shape="circle"
                block
                placement="right"
                icon="pencil"
                to={{ pathname: `${PATHS.WIZARD_TAXPROFILE}/${item.id}`, state: item }}
                aria-label={formatMessage({ id: "common.edit" })}
              />
            </Tooltip>
          )}
        </Grid.Item>
      </Grid>
    );
  }

  if (taxProfiles.records.length === 0) {
    return (
      <Loader spinning={status === BaseStatus.LOADING}>
        <div style={{ textAlign: "center", margin: "60px 0" }}>
          <Text padded>
            <Icon size="xlarge" theme="light" type="file-alt" />
          </Text>
          <Text strong padded>
            {formatMessage({ id: "containers.taxProfile.notTaxProfileSubmitted" })}
          </Text>
          <Divider transparent />
          <LinkButton
            size="large"
            icon="plus"
            type="primary"
            to={
              pathname === PATHS.WIZARD_TAXPROFILE_NEW
                ? pathname
                : { pathname, state: { modularWidgetPath: PATHS.WIZARD_TAXPROFILE_NEW } }
            }
          >
            {formatMessage({ id: "containers.taxProfile.addTaxProfile" })}
          </LinkButton>
        </div>
      </Loader>
    );
  }

  return (
    <Loader spinning={status === BaseStatus.LOADING}>
      <Container>
        <TitleBar level={2}>{formatMessage({ id: "containers.taxProfile.taxProfiles" })}</TitleBar>
        <List<TaxProfile>
          items={taxProfiles.records}
          renderItem={renderTaxProfile}
          defaultRecordCount={1}
          showMoreText={formatMessage({ id: "containers.taxProfile.seeMoreTaxProfiles" })}
          showLessText={formatMessage({ id: "containers.taxProfile.seeLessTaxProfiles" })}
        />
        <Divider transparent size="small" />
        <LinkButton
          icon="plus"
          type="primary"
          to={
            pathname === PATHS.WIZARD_TAXPROFILE_NEW
              ? pathname
              : { pathname, state: { modularWidgetPath: PATHS.WIZARD_TAXPROFILE_NEW } }
          }
          placement="right"
        >
          {formatMessage({ id: "containers.taxProfile.completeTaxProfile" })}
        </LinkButton>
      </Container>
    </Loader>
  );
}
