import { Container, Divider, Form2, GreyBox, Icon, Typography } from "components";
import React from "react";
import { useTheme } from "style/classname";
import { WizardPageProps } from ".";
import { useIntl } from "utils/context";

interface IFormData {
  confirmName: string;
  recipientFirstName?: string;
  recipientLastName?: string;
}

export default function ConfirmRecipientName({ taxProfileWizard, onContinue, isLoading }: WizardPageProps) {
  const { formatMessage } = useIntl();
  const [form] = Form2.useForm<IFormData>();
  const token = useTheme();
  const initialValues: IFormData = {
    confirmName: !taxProfileWizard.confirmName
      ? !taxProfileWizard.payoutRecipientFirstName || !taxProfileWizard.payoutRecipientLastName
        ? "no"
        : ""
      : taxProfileWizard.confirmName,
    recipientFirstName: taxProfileWizard.recipientFirstName ?? "",
    recipientLastName: taxProfileWizard.recipientLastName ?? "",
  };
  const confirmName = Form2.useWatch("confirmName", form) ?? initialValues.confirmName;

  function onSubmit({ recipientFirstName, recipientLastName }: IFormData) {
    onContinue({
      confirmName,
      recipientFirstName: confirmName === "no" ? recipientFirstName : taxProfileWizard.payoutRecipientFirstName,
      recipientLastName: confirmName === "no" ? recipientLastName : taxProfileWizard.payoutRecipientLastName,
    });
  }

  return (
    <Form2
      form={form}
      initialValues={initialValues}
      validateTrigger="onChange"
      requiredMark={false}
      onFinish={onSubmit}
    >
      <Container padding="none">
        {taxProfileWizard.payoutRecipientFirstName && taxProfileWizard.payoutRecipientLastName && (
          <fieldset>
            <legend>
              <Typography.Title>
                {formatMessage({ id: "containers.taxProfile.wizard.confirmRecipientNameQuestion" })}
              </Typography.Title>
            </legend>
            <Divider transparent />
            <GreyBox size="2x" border={false}>
              <Typography.Text
                strong
              >{`${taxProfileWizard.payoutRecipientFirstName} ${taxProfileWizard.payoutRecipientLastName}`}</Typography.Text>
            </GreyBox>
            <Divider transparent size="xlarge" />
            <Form2.Item name="confirmName">
              <Form2.Radio.Group
                optionType="card"
                direction="vertical"
                name="radio"
                options={[
                  {
                    label: formatMessage(
                      { id: "containers.taxProfile.wizard.yesRecipientName" },
                      {
                        recipientName: `${taxProfileWizard.payoutRecipientFirstName} ${taxProfileWizard.payoutRecipientLastName}`,
                      },
                    ),
                    value: "yes",
                    disabled: !taxProfileWizard.payoutRecipientFirstName || !taxProfileWizard.payoutRecipientLastName,
                  },
                  {
                    label: formatMessage({ id: "containers.taxProfile.wizard.noRecipientName" }),
                    value: "no",
                  },
                ]}
              />
            </Form2.Item>
          </fieldset>
        )}

        {confirmName === "no" && (
          <fieldset>
            {(!taxProfileWizard.payoutRecipientFirstName || !taxProfileWizard.payoutRecipientLastName) && (
              <legend>
                <Typography.Title>
                  {formatMessage({ id: "containers.taxProfile.wizard.confirmRecipientNameTitle" })}
                </Typography.Title>
              </legend>
            )}

            <Divider transparent size="large" />
            <div
              style={
                taxProfileWizard.payoutRecipientFirstName && taxProfileWizard.payoutRecipientLastName
                  ? { borderLeft: `solid 1px ${token.grey1}`, paddingLeft: "32px", paddingRight: "15px" }
                  : { paddingRight: "15px" }
              }
            >
              <Form2.Item
                label={formatMessage({ id: "containers.taxProfile.wizard.enterRecipientFirstName" })}
                name="recipientFirstName"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: "containers.taxProfile.wizard.enterRecipientFirstNameMessage" }),
                  },
                ]}
              >
                <Form2.Input
                  type="text"
                  placeholder={formatMessage({ id: "containers.taxProfile.wizard.firstName" })}
                />
              </Form2.Item>
              <Divider transparent />
              <Form2.Item
                label={formatMessage({ id: "containers.taxProfile.wizard.enterRecipientLastName" })}
                name="recipientLastName"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: "containers.taxProfile.wizard.enterRecipientLastNameMessage" }),
                  },
                ]}
              >
                <Form2.Input type="text" placeholder={formatMessage({ id: "containers.taxProfile.wizard.lastName" })} />
              </Form2.Item>
            </div>
          </fieldset>
        )}
        <Divider transparent size="large" />
        <Form2.SubmitButton
          disabled={!confirmName || isLoading}
          text={formatMessage({ id: "common.continue" })}
          suffix={<Icon type="arrow-right" />}
        />
      </Container>
    </Form2>
  );
}
