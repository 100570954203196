import { CountryCode, CountryCodesEU } from "@trolley/common-frontend";
import { FormInstance } from "antd";
import { Container, Divider, Form2, HelpText, Icon, Typography } from "components";
import React from "react";
import { WizardPageProps } from ".";
import { useIntl } from "utils/context";

interface IFormData {
  vatCountry: string;
  vatNumber: string;
}

export default function EnterVATNumber({ taxProfileWizard, onContinue, isLoading }: WizardPageProps) {
  const { formatMessage } = useIntl();
  function getDefaultEUCountry(country: string) {
    if (!country) return "";

    return CountryCodesEU.includes(country as CountryCode) ? country : "";
  }
  const initialValues: IFormData = {
    vatCountry: getDefaultEUCountry(taxProfileWizard.vatCountry || taxProfileWizard.address?.country || ""),
    vatNumber:
      taxProfileWizard.vatNumber && !taxProfileWizard.vatNumber.includes("*") ? taxProfileWizard.vatNumber : "",
  };

  function onSubmit({ vatCountry, vatNumber }: IFormData) {
    onContinue({
      vatCountry,
      vatNumber,
    });
  }

  return (
    <Form2 initialValues={initialValues} validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
      <Container padding="none">
        <fieldset>
          <legend>
            <Typography.Title>
              {formatMessage({ id: "containers.taxProfile.wizard.enterVatNumberTitle" })}
            </Typography.Title>
          </legend>
          <HelpText
            term={formatMessage({ id: "containers.taxProfile.wizard.enterVatNumberHelpTitle" })}
            definition={formatMessage({ id: "containers.taxProfile.wizard.enterVatNumberHelpText" })}
          />
          <Divider transparent size="large" />
          <Form2.Item name="vatCountry">
            <Form2.SelectCountry includes={CountryCodesEU} />
          </Form2.Item>
          <Form2.Item name="vatNumber">
            <Form2.Input
              type="text"
              aria-label={formatMessage({ id: "containers.taxProfile.wizard.enterVatNumberTitle" })}
              maxLength={20}
            />
          </Form2.Item>
        </fieldset>
        <Divider transparent size="large" />
        <Form2.Item dependencies={["vatCountry", "vatNumber"]}>
          {(form: FormInstance<IFormData>) => {
            const vatCountry = form.getFieldValue("vatCountry");
            const vatNumber = form.getFieldValue("vatNumber");

            return (
              <Form2.SubmitButton
                disabled={!vatCountry || !vatNumber || isLoading}
                text={formatMessage({ id: "common.continue" })}
                suffix={<Icon type="arrow-right" />}
              />
            );
          }}
        </Form2.Item>
      </Container>
    </Form2>
  );
}
