import { FormInstance } from "antd";
import { Container, Form2, Icon, Typography } from "components";
import React from "react";
import { useClientIntegration, useIntl } from "utils/context";
import { useWindowSize } from "utils/hooks";
import { WizardPageProps } from ".";

interface IFormData {
  confirmGovernmentEntity: string;
}

export default function ConfirmGovernmentPublicEntity({ taxProfileWizard, onContinue, isLoading }: WizardPageProps) {
  const client = useClientIntegration();
  const { isMobile } = useWindowSize();
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    confirmGovernmentEntity: taxProfileWizard.confirmGovernmentEntity ?? "",
  };

  function onSubmit({ confirmGovernmentEntity }: IFormData) {
    onContinue({
      confirmGovernmentEntity,
    });
  }

  return (
    <Form2 initialValues={initialValues} validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
      <Container padding="none">
        <fieldset>
          <legend>
            <Typography.Title>
              {formatMessage({ id: "containers.taxProfile.wizard.confirmGovernmentPublicEntityTitle" })}
            </Typography.Title>
          </legend>
          <Form2.Item name="confirmGovernmentEntity">
            <Form2.Radio.Group
              optionType="card"
              name="radio"
              direction={client === "widget" || isMobile ? "vertical" : "horizontal"}
              options={[
                {
                  label: formatMessage({ id: "common.yes" }),
                  value: "yes",
                },
                {
                  label: formatMessage({ id: "common.no" }),
                  value: "no",
                },
              ]}
            />
          </Form2.Item>
        </fieldset>
        <Form2.Item dependencies={["confirmGovernmentEntity"]}>
          {(form: FormInstance<IFormData>) => {
            const confirmGovernmentEntity = form.getFieldValue("confirmGovernmentEntity");

            return (
              <Form2.SubmitButton
                disabled={!confirmGovernmentEntity || isLoading}
                text={formatMessage({ id: "common.continue" })}
                suffix={<Icon type="arrow-right" />}
              />
            );
          }}
        </Form2.Item>
      </Container>
    </Form2>
  );
}
