import { FormInstance } from "antd";
import { Container, Divider, Form2, Icon, Typography } from "components";
import React from "react";
import { WizardPageProps } from ".";
import { useIntl } from "utils/context";

interface IFormData {
  country: string;
  city: string;
}

export default function ConfirmBirthplace({ taxProfileWizard, onContinue, isLoading }: WizardPageProps) {
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    country: taxProfileWizard.birthplace?.country ?? "",
    city: taxProfileWizard.birthplace?.city ?? "",
  };

  function onSubmit({ country, city }: IFormData) {
    onContinue({
      birthplace: {
        country,
        city,
      },
    });
  }

  return (
    <Form2 initialValues={initialValues} validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
      <Container padding="none">
        <fieldset>
          <legend>
            <Typography.Title>{formatMessage({ id: "containers.taxProfile.wizard.placeOfBirth" })}</Typography.Title>
          </legend>
          <Divider transparent size="large" />
          <Form2.Item
            name="country"
            label={formatMessage({ id: "containers.taxProfile.wizard.countryPlaceOfBirth" })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.taxProfile.wizard.countryPlaceOfBirthMessage" }),
              },
            ]}
          >
            <Form2.SelectCountry type="all" />
          </Form2.Item>
          <Divider transparent />
          <Form2.Item
            name="city"
            label={formatMessage({ id: "containers.taxProfile.wizard.cityPlaceOfBirth" })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: "containers.taxProfile.wizard.cityPlaceOfBirthMessage" }),
              },
            ]}
          >
            <Form2.Input type="text" placeholder={formatMessage({ id: "containers.taxProfile.wizard.city" })} />
          </Form2.Item>
        </fieldset>
        <Divider transparent size="large" />
        <Form2.Item dependencies={["country", "city"]}>
          {(form: FormInstance<IFormData>) => {
            const country = form.getFieldValue("country");
            const city = form.getFieldValue("city");

            return (
              <Form2.SubmitButton
                disabled={!country || !city || isLoading}
                text={formatMessage({ id: "common.continue" })}
                suffix={<Icon type="arrow-right" />}
              />
            );
          }}
        </Form2.Item>
      </Container>
    </Form2>
  );
}
