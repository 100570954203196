import { FormInstance } from "antd";
import React from "react";

import { Container, Form2, HelpText, Icon, Typography } from "components";
import { WizardPageProps } from ".";
import { useIntl } from "utils/context";

interface IFormData {
  confirmVATNumber?: string;
}

export default function ConfirmVATNumber({ taxProfileWizard, onContinue, isLoading }: WizardPageProps) {
  const { formatMessage } = useIntl();
  const initialValues: IFormData = {
    confirmVATNumber: taxProfileWizard.confirmVATNumber ?? "",
  };

  function onSubmit({ confirmVATNumber }: IFormData) {
    onContinue({
      confirmVATNumber,
      ...(confirmVATNumber === "no" && {
        vatCountry: "",
        vatNumber: "",
      }),
    });
  }

  return (
    <Form2 initialValues={initialValues} validateTrigger="onChange" requiredMark={false} onFinish={onSubmit}>
      <Container padding="none">
        <fieldset>
          <legend>
            <Typography.Title>
              {formatMessage({ id: "containers.taxProfile.wizard.confirmVatNumberTitle" })}
            </Typography.Title>
          </legend>
          <HelpText
            term={formatMessage({ id: "containers.taxProfile.wizard.confirmVatNumberHelpTitle" })}
            definition={formatMessage({ id: "containers.taxProfile.wizard.confirmVatNumberHelpText" })}
          />
          <Form2.Item name="confirmVATNumber">
            <Form2.Radio.Group
              optionType="card"
              direction="vertical"
              name="radio"
              options={[
                {
                  label: formatMessage({ id: "containers.taxProfile.wizard.yesVatNumber" }),
                  value: "yes",
                },
                {
                  label: formatMessage({ id: "common.no" }),
                  value: "no",
                },
              ]}
            />
          </Form2.Item>
        </fieldset>
        <Form2.Item dependencies={["confirmVATNumber"]}>
          {(form: FormInstance<IFormData>) => {
            const confirmVATNumber = form.getFieldValue("confirmVATNumber");

            return (
              <Form2.SubmitButton
                disabled={!confirmVATNumber || isLoading}
                text={formatMessage({ id: "common.continue" })}
                suffix={<Icon type="arrow-right" />}
              />
            );
          }}
        </Form2.Item>
      </Container>
    </Form2>
  );
}
